var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',_vm._l((_vm.targetAndActualSops),function(sop){return _c('v-chip',{key:_vm.qmsRequirement.requirementId + '--' + sop.versionId,staticClass:"mr-2 mb-2",attrs:{"small":""}},[_c('router-link',{class:{ 'strikethrough' : _vm.isSopNotAssignedAsTargetAnymore(sop.artifactId) },attrs:{"to":("/processes/" + (sop.versionId) + "#requirement-id=" + (_vm.qmsRequirement.regulationId) + "---" + (_vm.qmsRequirement.requirementId))}},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v(" $coProcessHouse ")]),_vm._v(" "+_vm._s(sop.name)+" ")],1),(_vm.isSopAssignedAsTargetAndImplemented(sop.artifactId))?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"ml-1",attrs:{"data-test-implemented":sop.artifactId,"color":"success","small":""}},on),[_vm._v(" mdi-checkbox-marked-outline ")])]}}],null,true)},[_c('span',[_vm._v("The implementation of the requirement in this process has been confirmed.")])]):(_vm.isSopAssignedAsTargetAndNotImplemented(sop.artifactId))?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"ml-1",attrs:{"data-test-assigned":sop.artifactId,"color":"warning","small":""}},on),[_vm._v(" mdi-alert-outline ")])]}}],null,true)},[_c('span',[_vm._v("The implementation of the requirement in this process has not yet been confirmed.")])]):(_vm.isSopNotAssignedAsTargetAnymore(sop.artifactId))?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"ml-1 not-strikethrough",attrs:{"data-test-not-assigned-but-implemented":sop.artifactId,"color":"warning","small":""}},on),[_vm._v(" mdi-checkbox-blank-off-outline ")])]}}],null,true)},[_c('span',[_vm._v("The implementation of this requirement is confirmed for this process. However, the process "),_c('br'),_vm._v("is no longer the target process for the implementation of this requirement.")])]):_vm._e()],1)}),1),_c('div',_vm._l((_vm.templateContents(_vm.qmsRequirement.templateContentIds)),function(templateContent){return _c('template-content-chip',{key:_vm.qmsRequirement.requirementId + '--' + templateContent.id,attrs:{"template-content":templateContent,"template-content-validations":_vm.templateContentValidationsById(templateContent.id),"data-test-template-content":_vm.qmsRequirement.requirementId + '--' + templateContent.id},on:{"click":function($event){return _vm.selectTemplateContentDetailWidgetId(templateContent.id)}}})}),1),_c('div',_vm._l((_vm.targetAndActualTemplates),function(template){return _c('v-chip',{key:_vm.qmsRequirement.requirementId + '--' + template.versionId,staticClass:"mr-2 mb-2",attrs:{"small":""}},[_c('router-link',{class:{ 'strikethrough' : _vm.isTemplateNotAssignedAsTargetAnymore(template.artifactId) },attrs:{"to":("/templates/" + (template.versionId))}},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v(" $coTemplate ")]),_vm._v(" "+_vm._s(template.name)+" ")],1),(_vm.isTemplateAssignedAsTargetAndImplemented(template.artifactId))?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"ml-1",attrs:{"data-test-implemented":template.artifactId,"color":"success","small":""}},on),[_vm._v(" mdi-checkbox-marked-outline ")])]}}],null,true)},[_c('span',[_vm._v("The implementation of the requirement in this template has been confirmed.")])]):(_vm.isTemplateAssignedAsTargetAndNotImplemented(template.artifactId))?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"ml-1",attrs:{"data-test-assigned":template.artifactId,"color":"warning","small":""}},on),[_vm._v(" mdi-alert-outline ")])]}}],null,true)},[_c('span',[_vm._v("The implementation of the requirement in this template has not yet been confirmed.")])]):(_vm.isTemplateNotAssignedAsTargetAnymore(template.artifactId))?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"ml-1 not-strikethrough",attrs:{"data-test-not-assigned-but-implemented":template.artifactId,"color":"warning","small":""}},on),[_vm._v(" mdi-checkbox-blank-off-outline ")])]}}],null,true)},[_c('span',[_vm._v("The implementation of this requirement is confirmed for this template. However, the template "),_c('br'),_vm._v("is no longer the target template for the implementation of this requirement.")])]):_vm._e()],1)}),1),_c('div',_vm._l((_vm.targetProductRequirements),function(productRequirement){return _c('v-chip',{key:_vm.qmsRequirement.requirementId + '--' + productRequirement.sopBlockArtifactId,staticClass:"mr-2 mb-2",attrs:{"small":""},on:{"click":function($event){return _vm.openProductRequirementWidget(productRequirement)}}},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v(" mdi-package-variant-closed ")]),_vm._v(" "+_vm._s(productRequirement.sopBlockName)+" ("+_vm._s(productRequirement.sopName)+") ")],1)}),1),(_vm.qmsRequirement.applicabilityReason && _vm.qmsRequirement.applicabilityReason.length > 0)?_c('div',{staticClass:"requirement-text mt-1 mb-3 mr-1 d-flex align-content-start flex-wrap"},[_c('div',{staticClass:"mr-1 co-subheader applicable-infos",staticStyle:{"min-width":"140px"}},[_vm._v("Applicability Reason:")]),_c('div',{attrs:{"data-test-applicability-reason":""}},[_vm._v(_vm._s(_vm.qmsRequirement.applicabilityReason))])]):_vm._e(),(_vm.qmsRequirement.notes && _vm.qmsRequirement.notes.length > 0)?_c('div',{staticClass:"requirement-text mt-1 mb-3 mr-1 d-flex align-content-start flex-wrap"},[_c('div',{staticClass:"mr-1 co-subheader applicable-infos",staticStyle:{"min-width":"140px"}},[_vm._v("Notes:")]),_c('div',{attrs:{"data-test-notes":""}},[_vm._v(_vm._s(_vm.qmsRequirement.notes))])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }