/* eslint-disable @typescript-eslint/no-unused-vars */

import { toMap } from '@/utils/util';
import { RelationRelease } from '@/features/sr-model/RelationRelease';
import { Relation } from '@/features/sr-model/Relation';
import { RelationReleaseDetail, SrRelationService } from '@/services/sr-relation-service';


export class SrRelationServiceMock implements SrRelationService {

    static relationRelease: RelationRelease;
    static relations: Relation[];

    public static createEmptyRelation() {
        return {
            requirementId: '',
            ruleIds: [],
            templateContentIds: [],
            roleIds: [],
            reportIds: [],
            termIds: [],
            sopContentIds: [],
            linkedProcessIds: [],
            sopContentLinkedProcessIds: [],
            templateContentLinkedProcessIds: [],
            references: '',
        }
    }

    public static initMock() {
        SrRelationServiceMock.relationRelease = {
            id: 'd241fba4-a330-4123-8609-e8ed0cd9d4ae',
            regulationId: 'mdr-2017-745',
            requirementRelations: [],
            ruleVersion: { version: '1' },
            status: 'DRAFT'
        };

        SrRelationServiceMock.relations = [
            {
                ...SrRelationServiceMock.createEmptyRelation(),
                requirementId: 'CHAPTER-I___________________________',
                ruleIds: ['03226b22-2817-4b10-b23d-2f6e094af69f', 'e3d9e757-22b5-4653-a60a-efcb36ac8e17'],
            },
            {
                ...SrRelationServiceMock.createEmptyRelation(),
                requirementId: 'CHAPTER-I_2_________________________',
                ruleIds: ['03226b22-2817-4b10-b23d-2f6e094af69f', 'e3d9e757-22b5-4653-a60a-efcb36ac8e17'],
                templateContentIds: ['sr-template-content-1', 'sr-template-content-2'],
                roleIds: ['b78989e8-43eb-4dda-b2f9-f6464b35f8da'],
                reportIds: ['ba3e9d68-9c57-4c6f-95ef-fac975ed4957'],
                termIds: ['b502be9d-5e08-4145-9c17-6897fc0ba084'],
                sopContentIds: ['b6d6acf2-1478-4d15-8968-7fc54ea3c7bc'],
                linkedProcessIds: ['d3d8253c-94bc-45d5-9185-acca3383f5d0'],
                sopContentLinkedProcessIds: ['d3d8253c-94bc-45d5-9185-acca3383f5d0'],
            },
            {
                ...SrRelationServiceMock.createEmptyRelation(),
                requirementId: 'CHAPTER-I_2_1_______________________',
                templateContentIds: ['sr-template-content-1', 'sr-template-content-3'],
            },
            {
                ...SrRelationServiceMock.createEmptyRelation(),
                requirementId: 'CHAPTER-X___________________________',
                templateContentIds: ['sr-template-content-1'],
            },
            {
                ...SrRelationServiceMock.createEmptyRelation(),
                requirementId: 'CHAPTER-X_2_________________________',
            },
            {
                ...SrRelationServiceMock.createEmptyRelation(),
                requirementId: 'CHAPTER-X_2_1_______________________',
                ruleIds: ['33e5d19e-4436-4f73-9214-17fb4a6046b1'],
            }
        ];
    }

    static getRelations(): Relation[] {
        return SrRelationServiceMock.relations.map(r => ({ ...r }));
    }

    loadReleases(regulationId: string): Promise<RelationRelease[]> {
        return this.loadReleaseDetail(SrRelationServiceMock.relationRelease.id)
            .then(r => [r.release]);
    }

    loadReleaseDetail(relationReleaseId: string): Promise<RelationReleaseDetail> {
        return Promise.resolve({
            release: SrRelationServiceMock.relationRelease,
            relations: toMap(SrRelationServiceMock.getRelations(), r => r.requirementId)
        });
    }

}

SrRelationServiceMock.initMock();
