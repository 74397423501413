import { ImpactsGetter, ImpactsState, ImpactUiEntry } from '@/features/impacts/store';
import { ImpactMap } from '@/features/impacts/model';
import { createObjectCsvStringifier as createCsvStringifier } from 'csv-writer';
import { Requirement } from '@/features/sr-model/Requirement';
import { QmsRequirement } from '@/services/model';
import { SopGetters } from '@/features/sops';
import { SopSearchPart } from '@/model';
import { TemplateContentGetters } from '@/features/template-content/template-content-store';
import { TemplateContent } from '@/features/template-content/template-content-model';
import { TemplateGetters } from '@/store/templates';
import { Template } from '@/model/Template';
import { RegulationDetailGetters } from '@/store/regulation-detail';
import { ProductRequirement } from '@/features/sop-block/model';
import { ImpactAnalysis } from '@/features/impact-analysis/model';

type CsvEntry = {
    fReqId?: string;
    fReqText?: string;
    fReqApp?: string;
    fReqRea?: string;
    fReqEvi?: string;
    fSrRef?: string;
    tReqId?: string;
    tReqText?: string;
    tReqApp?: string;
    tReqRea?: string;
    tReqEvi?: string;
    tSrRef?: string;
    iCha?: string;
    iChaTxt?: string;
    iImp?: string;
    iImpTxt?: string;
}

function strip(html: string) {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.textContent || '';
}

function exportImpact(impactId: string | undefined, impacts: ImpactMap, impactAnalysis: ImpactAnalysis) {
    if (!impactId) {
        return {};
    }
    const impact = impacts[impactId];
    return {
        iCha: impact.changeType,
        iChaTxt: impact.changeDescription,
        iImp: impact.impactType,
        iImpTxt: impact.impactDescription,
        iLink: process.env.VUE_APP_REGULATIONS_BASE_URL + '/impact-analysis/' + impactAnalysis.id + '#impact=' + impactId,
    }
}

function evidences(qmsRequirement: QmsRequirement, state: ImpactsState, getters: any, suffix: string) {

    const processEvidences = (getters[SopGetters.SELECTABLE_SOPS_BY_ARTIFACT_IDS](qmsRequirement.targetSopArtifactIds) as SopSearchPart[])
        .map(sop => sop.name)
        .join(', ');

    const templateContentEvidences = (getters[TemplateContentGetters.TEMPLATE_CONTENTS_BY_IDS_OR_LOADING](qmsRequirement.templateContentIds) as TemplateContent[])
        .map(templateContent => templateContent.name)
        .join(', ');

    const templateEvidences = (getters[TemplateGetters.VALID_TEMPLATE_ARTIFACTS] as Template[])
        .filter(template => qmsRequirement.targetTemplateArtifactIds.includes(template.artifactId))
        .map(templateContent => templateContent.name)
        .join(', ');

    const productEvidences = (getters[RegulationDetailGetters.PRODUCT_REQUIREMENTS] as ProductRequirement[])
        .filter(product => qmsRequirement.targetSopBlockArtifactIds.includes(product.sopBlockArtifactId))
        .map(productRequirement => productRequirement.sopBlockName + ' (' + productRequirement.sopName + ')')
        .join(', ');

    return ({
        [suffix + 'ReqEviProc']: processEvidences,
        [suffix + 'ReqEviTc']: templateContentEvidences,
        [suffix + 'ReqEviTemp']: templateEvidences,
        [suffix + 'ReqEviProd']: productEvidences,
    });
}

function exportFrom(uiEntry: ImpactUiEntry, state: ImpactsState, getters: any) {
    const fromRequirementsInCorrectOrder: Requirement[] = getters[ImpactsGetter.FROM_REQUIREMENTS_BY_IDS](uiEntry.fromRequirementIds)
    let currentTitle = '';
    return fromRequirementsInCorrectOrder.map(requirement => {
        const qmsRequirement = state.fromQmsRequirements[requirement.id].interpretedInRequirementId ? state.fromQmsRequirements[state.fromQmsRequirements[requirement.id].interpretedInRequirementId || ''] : state.fromQmsRequirements[requirement.id];
        currentTitle = requirement.type == 'HEADING' ? strip(requirement.text) : currentTitle
        const srRef = (getters[ImpactsGetter.REGULATION_REFERENCES_BY_FROM_REQUIREMENT_ID](requirement.id) as string).trim()
        return ({
            fReqId: requirement.id,
            fReqTitle: currentTitle,
            fReqText: strip(requirement.text),
            fReqInh: state.fromQmsRequirements[requirement.id].interpretedInRequirementId || '',
            fReqApp: qmsRequirement.applicability,
            fReqRea: qmsRequirement.applicabilityReason,
            fReqNot: qmsRequirement.notes,
            fSrRef: strip(srRef.replaceAll('</li>', '</li>\n').replaceAll('<li>', '<li> - ')),
            fReqLink: 'https://app.colabon.com/sr-list/' + state.fromRegulation.id + '#' + requirement.key,
            ...evidences(qmsRequirement, state, getters, 'f')
        });
    })
}

function exportTo(uiEntry: ImpactUiEntry, state: ImpactsState, getters: any) {
    let currentTitle = '';
    const toRequirementsInCorrectOrder: Requirement[] = getters[ImpactsGetter.TO_REQUIREMENTS_BY_IDS](uiEntry.toRequirementIds)
    return toRequirementsInCorrectOrder.map(requirement => {
        const qmsRequirement = state.toQmsRequirements[requirement.id].interpretedInRequirementId ? state.toQmsRequirements[state.toQmsRequirements[requirement.id].interpretedInRequirementId || ''] : state.toQmsRequirements[requirement.id];
        currentTitle = requirement.type == 'HEADING' ? strip(requirement.text) : currentTitle
        const srRef = (getters[ImpactsGetter.REGULATION_REFERENCES_BY_TO_REQUIREMENT_ID](requirement.id) as string).trim()
        return ({
            tReqId: requirement.id,
            tReqTitle: currentTitle,
            tReqText: strip(requirement.text),
            tReqInh: state.toQmsRequirements[requirement.id].interpretedInRequirementId || '',
            tReqApp: qmsRequirement.applicability,
            tReqRea: qmsRequirement.applicabilityReason,
            tReqNot: qmsRequirement.notes,
            tSrRef: strip(srRef.replaceAll('</li>', '</li>\n').replaceAll('<li>', '<li> - ')),
            tReqLink: 'https://app.colabon.com/sr-list/' + state.toRegulation.id + '#' + requirement.key,
            ...evidences(qmsRequirement, state, getters, 't')
        });
    })
}

function mergeInheritedFrom<T extends { fReqId: string; fReqText: string; fReqInh: string; fSrRef: string }>(exportEntries: T[]): T[] {

    const returnEntries = [];
    let lastInterpretedEntry = null;
    for (const exportEntry of exportEntries) {
        if (!exportEntry.fReqInh || !lastInterpretedEntry) {
            // normal case: first entry is the impact and therefore not inherited
            // corner case: first entry is inherited. so just add it to the list, that we have it in the export
            lastInterpretedEntry = exportEntry;
            returnEntries.push(lastInterpretedEntry);
        } else if (exportEntry.fReqInh && lastInterpretedEntry) {
            // normal case: entry is inherited, so we add the text it to the last inherited entry
            lastInterpretedEntry.fReqText = lastInterpretedEntry.fReqText + '\n' + exportEntry.fReqText;
            lastInterpretedEntry.fSrRef = lastInterpretedEntry.fSrRef + (lastInterpretedEntry.fSrRef.length > 0 ? '\n\n' : '') + exportEntry.fSrRef;
        } else {
            console.error('This case should not happen');
        }
    }
    return returnEntries;
}

function mergeInheritedTo<T extends { tReqId: string; tReqText: string; tReqInh: string; tSrRef: string }>(exportEntries: T[]): T[] {
    const returnEntries = [];
    let lastInterpretedEntry = null;
    for (const exportEntry of exportEntries) {
        if (!exportEntry.tReqInh || !lastInterpretedEntry) {
            // normal case: first entry is the impact and therefore not inherited
            // corner case: first entry is inherited. so just add it to the list, that we have it in the export
            lastInterpretedEntry = exportEntry;
            returnEntries.push(lastInterpretedEntry);
        } else if (exportEntry.tReqInh && lastInterpretedEntry) {
            // normal case: entry is inherited, so we add the text it to the last inherited entry
            lastInterpretedEntry.tReqText = lastInterpretedEntry.tReqText + '\n' + exportEntry.tReqText;
            lastInterpretedEntry.tSrRef = lastInterpretedEntry.tSrRef + (lastInterpretedEntry.tSrRef.length > 0 ? '\n\n' : '') + exportEntry.tSrRef;
        } else {
            console.error('This case should not happen');
        }
    }
    return returnEntries;
}

function download(content: string, fileName: string) {
    const a = document.createElement('a');
    const mimeType = 'text/csv;encoding:utf-8';

    if (URL && 'download' in a) { // html5 A[download]
        a.href = URL.createObjectURL(new Blob([content], {
            type: mimeType
        }));
        a.setAttribute('download', fileName);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    } else {
        location.href = 'data:application/octet-stream,' + encodeURIComponent(content); // only this mime type is supported
    }
}

export class ImpactCsvExporter {

    static exportImpactsWithInterpretatedRequirements(state: ImpactsState, getters: any) {

        const csvEntries = state.impactUiEntries.flatMap(uiEntry => {
            const expImpact = exportImpact(uiEntry.impactId, state.impacts, state.impactAnalysis);
            const expFrom = mergeInheritedFrom(exportFrom(uiEntry, state, getters));
            const expTo = mergeInheritedTo(exportTo(uiEntry, state, getters));

            const size = Math.max(expFrom.length, expTo.length);

            const entries: CsvEntry[] = []
            for (let i = 0; i < size; i++) {
                let entry = {};
                if (expTo[i]) {
                    entry = {
                        ...entry,
                        ...expTo[i]
                    }
                }
                if (expFrom[i]) {
                    entry = {
                        ...entry,
                        ...expFrom[i]
                    }
                }
                if (i === 0 && expImpact) {
                    entry = {
                        ...entry,
                        ...expImpact,
                    }
                }
                entries.push(entry);
            }
            return entries;
        })

        const csvStringifier = createCsvStringifier({
            header: [
                { id: 'fReqId', title: 'Id From' },
                { id: 'fReqTitle', title: 'Title From' },
                { id: 'fReqText', title: 'Text From' },
                { id: 'fReqInh', title: 'Inherited From' },
                { id: 'fReqApp', title: 'Applicability From' },
                { id: 'fReqRea', title: 'Reason From' },
                { id: 'fReqNot', title: 'Note From' },
                { id: 'fSrRef', title: 'S&R Reference From' },
                { id: 'fReqLink', title: 'Requirement Link From' },

                { id: 'fReqEviProc', title: 'Process Evidences From' },
                { id: 'fReqEviTc', title: 'Template Content Evidences From' },
                { id: 'fReqEviTemp', title: 'Template Evidences From' },
                { id: 'fReqEviProd', title: 'Process Evidences From' },

                { id: 'tReqId', title: 'Id To' },
                { id: 'tReqTitle', title: 'Title To' },
                { id: 'tReqText', title: 'Text To' },
                { id: 'tReqInh', title: 'Inherited To' },
                { id: 'tReqApp', title: 'Applicability To' },
                { id: 'tReqRea', title: 'Reason To' },
                { id: 'tReqNot', title: 'Note To' },
                { id: 'tSrRef', title: 'S&R Reference To' },
                { id: 'tReqLink', title: 'Requirement Link TO' },

                { id: 'tReqEviProc', title: 'Process Evidences To' },
                { id: 'tReqEviTc', title: 'Template Content Evidences To' },
                { id: 'tReqEviTemp', title: 'Template Evidences To' },
                { id: 'tReqEviProd', title: 'Process Evidences To' },

                { id: 'iCha', title: 'Change' },
                { id: 'iChaTxt', title: 'Change Text' },
                { id: 'iImp', title: 'Impact' },
                { id: 'iImpTxt', title: 'Impact Text' },
                { id: 'iLink', title: 'Impact Link' },
            ]
        });

        const header = csvStringifier.getHeaderString();
        const content = csvStringifier.stringifyRecords(csvEntries);

        const filename = state.impactAnalysis.regulationIdFrom + '--to--' + state.impactAnalysis.regulationIdTo;

        download(header + '\n' + content, 'ImpactAnalysis_' + filename + '.csv');

        return csvEntries;
    }
}