


































import Component, { mixins } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import AnchoredHeading from '@/features/sr-requirement-search/AnchoredHeading.vue';
import RequirementItem from '@/components/common/RequirementItem.vue';
import { Requirement } from '@/features/sr-model/Requirement';
import ImpactsMixin from '@/features/impacts/components/ImpactsMixin.vue';
import { SopSearchPart } from '@/model';
import { TemplateContent } from '@/features/template-content/template-content-model';
import ImpactEntryToRequirementEditPart from '@/features/impacts/components/ImpactEntryToRequirementEditPart.vue';

@Component({
  components: { ImpactEntryToRequirementEditPart, RequirementItem, AnchoredHeading },
})
export default class ImpactEntryToRequirement extends mixins<ImpactsMixin>(ImpactsMixin) {

  @Prop()
  requirement: Requirement;
  @Prop({ default: () => [] })
  proposedSops: SopSearchPart[];
  @Prop({ default: () => [] })
  proposedTemplateContents: TemplateContent[];
}
