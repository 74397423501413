import { render, staticRenderFns } from "./QmsRequirementCurrent.vue?vue&type=template&id=1005c2e2&scoped=true&"
import script from "./QmsRequirementCurrent.vue?vue&type=script&lang=ts&"
export * from "./QmsRequirementCurrent.vue?vue&type=script&lang=ts&"
import style0 from "./QmsRequirementCurrent.vue?vue&type=style&index=0&id=1005c2e2&scoped=true&lang=css&"
import style1 from "./QmsRequirementCurrent.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1005c2e2",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VChip } from 'vuetify/lib/components/VChip';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VChip,VIcon,VTooltip})
