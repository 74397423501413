






















































































































import Component, { mixins } from 'vue-class-component';
import { mapActions, mapState } from 'vuex';
import { RootState } from '@/store';
import { changeTypes, humanReadableChangeType, humanReadableImpactType, ImpactDto, impactTypes } from '@/features/impacts/model';
import ImpactsMixin from '@/features/impacts/components/ImpactsMixin.vue';
import AnchoredHeading from '@/features/sr-requirement-search/AnchoredHeading.vue';
import RequirementItem from '@/components/common/RequirementItem.vue';
import { ImpactsActions, setterForImpactFields } from '@/features/impacts/store';
import FromRequirements from '@/features/impacts/components/FromRequirements.vue';
import { Watch } from 'vue-property-decorator';

@Component({
  components: { FromRequirements, RequirementItem, AnchoredHeading },
  computed: {
    ...mapState<RootState>({
      selectedImpact: (state: RootState) => state.impacts.selectedImpact,
      selectedImpactLoading: (state: RootState) => state.impacts.selectedImpactLoading,
      isImpactMode: (state: RootState) => state.impacts.isImpactMode,
    }),
  },
  methods: {
    ...mapActions({
      ...setterForImpactFields('changeDescription', 'impactType', 'impactDescription', 'changeType'),
      saveImpact: ImpactsActions.UPDATE_IMPACT,
      deleteImpact: ImpactsActions.DELETE_IMPACT,
      addNext: ImpactsActions.ADD_NEXT,
      addBefore: ImpactsActions.ADD_BEFORE,
      removeFirst: ImpactsActions.REMOVE_FIRST,
      removeLast: ImpactsActions.REMOVE_LAST,
    })
  }
})
export default class ImpactDetailWidget extends mixins<ImpactsMixin>(ImpactsMixin) {
  predefinedImpact = '';
  selectedImpact: ImpactDto & { uiId?: string };
  tab = 'tab-requirements';
  readonly humanReadableImpactType = humanReadableImpactType;
  readonly humanReadableChangeType = humanReadableChangeType;
  readonly changeTypes = changeTypes;
  readonly impactTypes = impactTypes;
  setImpactDescription: (value: string) => void;

  @Watch('isImpactMode', { immediate: true })
  onImpactModeChange(newValue: boolean) {
    this.tab = newValue ? 'tab-impact' : 'tab-change';
  }

  seekImpact() {
    if (this.selectedImpact.uiId) {
      window.location.replace(`#${ this.selectedImpact.uiId }`);
    } else {
      window.location.replace(`#${ this.selectedImpact.id }`);
    }
  }

  udpatedpredefinedImpactDescription(value: string) {
    this.setImpactDescription(value);
  }

  get predefinedImpactDescriptions() {
    return [
      '',
      'Informative clause, hence, no impact.',
      'Not applicable clause, hence no impact.',
      'Deleted clause, hence no impact.',
      'No change or similar clause, hence no impact.',
      'Impact to Process',
      'Impact to Product Requirement',
    ]
  }

}
