
































import Component, { mixins } from 'vue-class-component';
import { Requirement, RequirementMap } from '@/features/sr-model/Requirement';
import { mapState } from 'vuex';
import { RootState } from '@/store';
import { ImpactDto } from '@/features/impacts/model';
import ImpactsMixin from '@/features/impacts/components/ImpactsMixin.vue';
import AnchoredHeading from '@/features/sr-requirement-search/AnchoredHeading.vue';
import RequirementItem from '@/components/common/RequirementItem.vue';
import { ImpactsActions } from '@/features/impacts/store';
import { Watch } from 'vue-property-decorator';
import VirtualList from '@/components/common/incremental-list/VirtualList';
import TimingMixin from '@/components/common/TimingMixin.vue';

@Component({
  components: { RequirementItem, AnchoredHeading },
  computed: {
    ...mapState<RootState>({
      selectedImpact: (state: RootState) => state.impacts.selectedImpact,
      fromRequirementIdToImpactId: (state: RootState) => state.impacts.fromRequirementIdToImpactId,
    }),
  },
})
export default class FromRequirements extends mixins<ImpactsMixin, TimingMixin>(ImpactsMixin, TimingMixin) {

  selectedImpact: ImpactDto;
  fromRequirementIdToImpactId: { [key: string]: string };
  private virtualList = new VirtualList<Requirement>(this.createInterval, 100);

  get allFromRequirements(): readonly Requirement[] {
    return this.virtualList.elements;
  }

  @Watch('fromRegulationRequirement', { immediate: true })
  onListUpdate(newValue: RequirementMap) {
    this.virtualList.update(Object.values(newValue));
  }

  isSelected(requirementId: string): boolean {
    return this.selectedImpact.requirementIdsFrom.includes(requirementId);
  }

  canBeSelected(requirementId: string): boolean {
    return this.selectedImpact.requirementIdsFrom.includes(requirementId)
        || !this.fromRequirementIdToImpactId[requirementId];
  }

  updateImpactAddFromRequirement(fromRequirementId: string) {
    const updatedImpact: ImpactDto = {
      ...this.selectedImpact,
      requirementIdsFrom: [...this.selectedImpact.requirementIdsFrom],
    }
    const currentIndex = updatedImpact.requirementIdsFrom.indexOf(fromRequirementId);
    if (currentIndex >= 0) {
      updatedImpact.requirementIdsFrom.splice(currentIndex, 1);
    } else {
      updatedImpact.requirementIdsFrom.push(fromRequirementId);
    }
    return this.$store.dispatch(ImpactsActions.UPDATE_IMPACT, updatedImpact);
  }

  seekFirstSelected() {
    const firstSelected = this.allFromRequirements.find((req) => this.isSelected(req.id));
    const comp = (this.$refs[`req-${ firstSelected?.id }`] as any)[0];
    if (comp && comp.scrollIntoView) {
      comp.scrollIntoView({ behavior: 'smooth' });
    }
  }
}
